import type { ReactNode } from 'react';

import { MOBILE_MAX_WIDTH } from '@src/constants';
import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';

export const NavigationBottomBar = ({ children }: { children?: ReactNode }) => (
  <>
    <ul className="BottomNavigationBar">
      <div className="items">{children}</div>
    </ul>
    <style jsx>
      {`
        .BottomNavigationBar {
          display: flex;
          flex-direction: column;
          z-index: 10;
          box-shadow: ${rgba(colors.black, 0.05)} 0 -0.125rem 0 0;
        }

        @media screen and (min-width: ${MOBILE_MAX_WIDTH}) {
          .BottomNavigationBar {
            display: none;
          }
        }

        .BottomNavigationBar .items {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          min-height: 32px;
        }
      `}
    </style>
  </>
);
