import ReferIcon from '@public/static/svgs/refer.svg';
import { accent01Lowest, primary01 } from '@src/support/colors';
import { FontAwesomeIcon } from '@src/ui/font-awesome-icon';
import Link from 'next/link';

type Props = {
  text: string;
  onClick: () => void;
};

export const ReferLink = ({ text, onClick }: Props) => (
  <Link href="/invite" onClick={onClick}>
    <div className="ReferLink">
      <ReferIcon width="1.5rem" height="1.5rem" />
      <div className="item-label self-end">{text}</div>
      <LinkIndicator />
    </div>
    <style jsx>{`
      .ReferLink {
        display: flex;
        border-radius: 0.5rem;
        border: 1.5px solid ${primary01};
        background-color: ${accent01Lowest};
        align-items: center;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        flex-direction: row;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-right: 0.25rem;
        padding-left: 0.5rem;
        transition: background-color 300ms;
      }

      .ReferLink .item-label {
        flex: 1;
        font-size: 0.875rem;
        font-weight: 600;
        padding-left: 0.5rem;
      }
    `}</style>
  </Link>
);

const LinkIndicator = () => (
  <div className="LinkIndicator">
    <FontAwesomeIcon icon={['fal', 'chevron-right']} />
    <style jsx>{`
      .LinkIndicator {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
      }
    `}</style>
  </div>
);
