import type { InMemoryCacheConfig } from '@apollo/client';

import type { Job } from '@generated/graphql';
import generatedIntrospection from '@generated/possible-types.json';

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Location: {
      keyFields: location =>
        location.locationString ? ['locationString'] : ['id'],
    },
    JobsCollection: {
      fields: {
        recommendations: {
          // biome-ignore lint/style/useDefaultParameterLast: cannot change signature
          merge(existing = [], incoming, { readField }: any) {
            const existingIds = existing.map((job: Job) =>
              readField('id', job)
            );

            const newJobs = incoming.filter(
              (job: Job) => !existingIds.includes(readField('id', job))
            );

            return [...existing, ...newJobs];
          },
        },
        records: {
          // biome-ignore lint/style/useDefaultParameterLast: cannot change signature
          merge(existing = [], incoming, { readField }: any) {
            const existingIds = existing.map((job: Job) =>
              readField('id', job)
            );

            const newJobs = incoming.filter(
              (job: Job) => !existingIds.includes(readField('id', job))
            );

            return [...existing, ...newJobs];
          },
        },
      },
    },
    UserFeatureGate: {
      merge: true,
    },
  },
  possibleTypes: generatedIntrospection.possibleTypes,
};
