import { LastUsedTooltip } from '@src/components/sso-buttons/last-used-tooltip';
import { Button, FontAwesomeIcon } from '@src/ui';
import type { MouseEvent } from 'react';

type Provider = 'yahoo' | 'apple' | 'google' | 'facebook';

const brandLabel: Record<Provider, string> = {
  yahoo: 'Yahoo!',
  apple: 'Apple',
  google: 'Google',
  facebook: 'Facebook',
};

export const SSOButton = (props: {
  provider: Provider;
  onClick: (event: MouseEvent) => void;
}) => (
  <LastUsedTooltip provider={props.provider}>
    <Button
      color={props.provider}
      type="button"
      shape="rounded"
      variant={props.provider === 'google' ? 'white' : 'default'}
      onClick={props.onClick}
      fluid
    >
      <FontAwesomeIcon icon={['fab', props.provider]} fixedWidth />
      <span>Continue with {brandLabel[props.provider]}</span>
    </Button>
  </LastUsedTooltip>
);
