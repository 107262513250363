import { Inter } from 'next/font/google';
import type { ReactNode } from 'react';

const inter = Inter({ subsets: ['latin'] });

// Use in the application entrypoint like `_app.tsx` or similar
// It applies the global font-family.
export const GlobalFontFamily = ({ children }: { children: ReactNode }) => (
  <>
    {children}
    <style jsx global>{`
      body {
        font-family: ${inter.style.fontFamily};
      }
    `}</style>
  </>
);
