import { CookieName, getCookie, setCookie } from '@src/utils/cookies';

type CookieAdName = CookieName.Gclid | CookieName.GBraid | CookieName.WBraid;

export const getGclidParam = () =>
  new URLSearchParams(window.location.search).get('gclid');

export const getGBraidParam = () =>
  new URLSearchParams(window.location.search).get('gbraid');

export const getWBraidParam = () =>
  new URLSearchParams(window.location.search).get('wbraid');

export const storeAdClickIds = (value: string, cookieName: CookieAdName) => {
  setCookie(cookieName, value, { domain: '.trustedhealth.com' });
};

export const getGclid = () => getCookie(CookieName.Gclid);
export const getGBraid = () => getCookie(CookieName.GBraid);
export const getWBraid = () => getCookie(CookieName.WBraid);

export const getClickAdIds = () => ({
  gclid: getGclid(),
  gbraid: getGBraid(),
  wbraid: getWBraid(),
});
