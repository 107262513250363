import { useEffect, useRef, useState } from 'react';

export const useDimensions = () => {
  const ref = useRef<any>(undefined);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const changeDimensions = () => {
      if (!ref.current) return;

      setDimensions({
        width: ref.current?.offsetWidth || 0,
        height: ref.current?.offsetHeight || 0,
      });
    };

    changeDimensions();

    window.addEventListener('resize', changeDimensions);

    return () => {
      window.removeEventListener('resize', changeDimensions);
    };
  }, []);

  return { ref, dimensions };
};
