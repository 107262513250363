import getConfig from 'next/config';
import Script from 'next/script';

const { NEXT_PUBLIC_GTAG_TRACKING_ID } = getConfig().publicRuntimeConfig;

export const GTAG_TRACKING_ID = NEXT_PUBLIC_GTAG_TRACKING_ID;

export const Gtag = () => (
  <>
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_TRACKING_ID}`}
      async
    />
    <Script
      id="gtag"
      dangerouslySetInnerHTML={{
        __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GTAG_TRACKING_ID}');
        `,
      }}
    />
  </>
);
