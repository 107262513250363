import { type ReactNode, useContext } from 'react';

import { ChatContext } from '@src/contexts/chat-provider/chat-context';
import { WebChatProvider } from '@src/contexts/chat-provider/web-chat-provider';
import { WebViewChatProvider } from '@src/contexts/chat-provider/webview-chat-provider';
import { useMobileWebview } from '@src/hooks/use-mobile-webview';

export const ChatProvider = ({ children }: { children?: ReactNode }) => {
  const webView = useMobileWebview();

  return webView.isPresent ? (
    <WebViewChatProvider>{children}</WebViewChatProvider>
  ) : (
    <WebChatProvider>{children}</WebChatProvider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('Invalid context for chat');
  }

  return context;
};
