import type { PropsWithChildren } from 'react';

type Props = {
  align?: 'center' | 'start';
};

export const NavSidebarCardMenu = (props: PropsWithChildren<Props>) => (
  <ul className="NavSidebarCardMenu">
    {props.children}
    <style jsx>{`
      .NavSidebarCardMenu {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        padding-top: 0.125rem;
        align-items: ${props.align || 'center'};
      }

      .NavSidebarCardMenu :global(.NavigationLink) {
        padding-bottom: 0.5rem;
      }
    `}</style>
  </ul>
);
