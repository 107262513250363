import { Popper, type PopperProps } from '@mui/material';
import clsx from 'clsx';
import type { PropsWithChildren, RefObject } from 'react';

import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';
import { Portal } from '../portal';

type Props = {
  anchorRef?: RefObject<Element | null>;
  offsetX?: number;
  offsetY?: number;
  onClose?: () => void;
  open?: boolean;
  shape?: 'rounded';
  shadow?: 'default' | 'bottom';
  animate?: 'fade' | 'slideDown' | 'none';
  placement?: PopperProps['placement'];
};

export function Popover({
  anchorRef,
  offsetX = 0,
  offsetY = 0,
  open = false,
  shape = 'rounded',
  shadow = 'default',
  animate = 'fade',
  placement = 'bottom-start',
  onClose,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Portal>
      {open && <Overlay onClose={onClose} />}
      <Popper
        sx={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef?.current}
        placement={placement}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [offsetX, offsetY],
            },
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: 'document',
            },
          },
        ]}
      >
        <div
          className={clsx('PopoverContainer', {
            visible: open,
            'animate-fade': animate === 'fade',
            'animate-slide-down': animate === 'slideDown',
          })}
          tabIndex={-1}
          onKeyUp={event => {
            if (event.key === 'Escape') {
              onClose?.();
            }
          }}
        >
          <div
            className={clsx('Popover', {
              'shape-rounded': shape === 'rounded',
              'shadow-default': shadow === 'default',
              'shadow-bottom': shadow === 'bottom',
            })}
          >
            {children}
          </div>
          <style jsx>{`
            .PopoverContainer {
              outline: none;
            }

            .PopoverContainer,
            .PopoverContainer .Popover {
              visibility: hidden;
            }

            .PopoverContainer.visible,
            .PopoverContainer.visible .Popover {
              visibility: visible;
              opacity: 1;
            }

            .PopoverContainer .Popover {
              background-color: ${colors.white};
              overflow-x: hidden;
              overflow-y: auto;
              will-change: opacity, transform;
              opacity: 0;
              visibility: hidden;
            }

            .PopoverContainer.visible.animate-fade .Popover {
              transform: translateY(0%);
            }

            .PopoverContainer,
            .PopoverContainer.animate-fade .Popover {
              transition:
                opacity 200ms,
                visibility 200ms;
            }

            .PopoverContainer.animate-fade .Popover {
              transform: translateY(10%) scale(0.9);
            }

            .PopoverContainer.visible.animate-slide-down .Popover {
              transform: translateY(0%);
            }

            .PopoverContainer,
            .PopoverContainer.animate-slide-down .Popover {
              transition:
                opacity 300ms cubic-bezier(0.39, 0.58, 0.57, 1),
                transform 300ms cubic-bezier(0.39, 0.58, 0.57, 1),
                visibility 300ms;
            }

            .PopoverContainer.animate-slide-down .Popover {
              transform: translateY(-10%);
            }

            .PopoverContainer .Popover.shape-rounded {
              border-radius: 0.25em;
            }

            .PopoverContainer .Popover.shadow-default {
              box-shadow: 0 0 1.2307692307692308em ${rgba(colors.black, 0.16)};
            }

            .PopoverContainer .Popover.shadow-bottom {
              box-shadow: 0em 0.0625em 0.1875em ${rgba(colors.black, 0.16)};
            }
          `}</style>
        </div>
      </Popper>
    </Portal>
  );
}

type OverlayProps = {
  onClose?: () => void;
};

function Overlay({ onClose }: OverlayProps) {
  return (
    <div className="Overlay" onClick={onClose}>
      <style jsx>{`
        .Overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </div>
  );
}
