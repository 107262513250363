import { SEO } from '@src/constants';
import Head from 'next/head';
import type { PropsWithChildren } from 'react';

type Props = {
  description?: string;
  title?: string;
};

export function TrustedHead({
  children,
  description = SEO.DESCRIPTION,
  title = SEO.TITLE,
}: PropsWithChildren<Props>) {
  const fullTitle = `${title} | Trusted Health`;

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      <meta key="title" property="og:title" content={fullTitle} />
      <meta key="description" property="og:description" content={description} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      {children}
    </Head>
  );
}
