import type { ReactNode } from 'react';

import { NAV_MAIN_HEADER_BAR_HEIGHT } from './nav-main-header-bar';

export const NavSidebarCardHeader = (props: { children?: ReactNode }) => (
  <div className="NavSidebarCardHeader">
    {props.children}
    <style jsx>{`
      .NavSidebarCardHeader {
        align-items: center;
        display: flex;
        height: calc(${NAV_MAIN_HEADER_BAR_HEIGHT} - 2px);
        overflow: hidden;
        position: relative;
        justify-content: center;
      }
    `}</style>
  </div>
);
