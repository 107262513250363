import { SignInUserDocument } from '@generated/graphql';
import type { AuthSuccessResult } from '@src/apiCalls';
import { FormikSubmitButton, FormikTextField } from '@src/components/formik';
import {
  AppleButton,
  FacebookButton,
  GoogleButton,
  YahooButton,
} from '@src/components/sso-buttons';
import { useMutation } from '@src/hooks/use-graphql-hooks';
import { useSnackbarMessenger } from '@src/hooks/use-snackbar-messenger';
import { AuthLayout } from '@src/layouts/auth-layout';
import { analytics } from '@src/lib/analytics';
import { initializeSession } from '@src/lib/auth';
import { neutralDefault, neutralHigh } from '@src/support/colors';
import { Form, Formik } from '@src/ui/formik';
import { FormControl, Grid } from '@src/ui/material-ui';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const { NODE_ENV, API_HOST } = getConfig().publicRuntimeConfig;

export function LoginPage() {
  const [errorMessage, setErrorMessage] = useState<null | string>(null);

  const router = useRouter();
  const [snackbar] = useSnackbarMessenger();

  /**
   * Initiates an SSO redirect (if requested, based on URL parameters).
   * This function should only be invoked after successful user authentication
   * (Email, Google, Facebook, etc.) and as part of the callback process.
   *
   * On detecting a requested SSO redirect, function navigates the user away from the app
   * and initiates an SSO handshake with the requesting Service Provider.
   *
   * @returns [boolean] `true` if redirect was requested and taken, `false` otherwise
   */
  const initiateSingleSignOnRedirectIfNeeded = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('sso_sp')) {
      // SSO redirect is requested

      // For `dev`, ensure domain is normalized (similar to `prod`) so that cross-origin cookie requests work correctly.
      // NOTE: This requirement is not currently expected outside this function/scenario.
      const apiHost = API_HOST.replace('127.0.0.1', 'localhost');

      const ssoURL = new URL(`${apiHost}/sso/auth`);

      // Forward current query params to destination url and redirect.
      // Note we use `window.location` instead of `router` because we're
      // exiting the application and want to ensure a side-effect free redirect.
      ssoURL.search = window.location.search;
      window.location.href = ssoURL.toString();
      return true;
    }
    // no SSO redirect is needed
    return false;
  };

  const [signInUser] = useMutation(SignInUserDocument, {
    onCompleted(data) {
      if (data?.signInUser) {
        initializeSession({
          ...data.signInUser.user,
          uuid: data.signInUser.user.id,
          authToken: data.signInUser.token,
          intercomUserHash: data.signInUser.user.intercomUserHash || undefined,
        });

        if (initiateSingleSignOnRedirectIfNeeded()) {
          // exit from app
          return;
        }

        // otherwise redirect to application
        router.push({ pathname: '/', query: router.query });
      }
    },
  });

  const { email_confirmed } = router.query;

  useEffect(() => {
    if (typeof email_confirmed === 'string') {
      if (email_confirmed === 'true') {
        snackbar.addSuccessMessage('Email verification complete!');
      } else {
        snackbar.addErrorMessage(
          'Sorry, the confirmation link has expired! Please try again'
        );
      }
      router.replace(router.pathname, { query: router.query });
    }
  }, [router, email_confirmed, snackbar]);

  useEffect(() => {
    analytics.page('login');
  }, []);

  const title =
    email_confirmed === 'true'
      ? 'Email Verified! Please Log In'
      : 'Welcome back';

  const redirectURL =
    typeof window === 'undefined'
      ? `${NODE_ENV}/`
      : `${window.location.origin}/`;

  const success = (results: AuthSuccessResult) => {
    initializeSession({
      uuid: results.uuid,
      email: results.email,
      firstName: results.first_name,
      lastName: results.last_name,
      authToken: results.auth_token,
      intercomUserHash: results.intercom_user_hash,
    });

    if (initiateSingleSignOnRedirectIfNeeded()) {
      // exit from app
      return;
    }

    // otherwise redirect to application
    router.push('/');
  };

  return (
    <AuthLayout title={title}>
      <Formik
        initialValues={{ password: '', email: '' }}
        onSubmit={async input => {
          setErrorMessage(null);
          localStorage.removeItem('LAST_USED_SSO');

          try {
            const response = await signInUser({ variables: { input } });
            if (response.errors) {
              setErrorMessage(response.errors[0].message);
            }
          } catch (error: unknown) {
            setErrorMessage((error as Error).message);
          }
        }}
      >
        {({ setSubmitting }) => {
          const handleSSOResult = (results: AuthSuccessResult) => {
            setErrorMessage(null);
            setSubmitting(false);
            success(results);
          };

          const handleSSOError = (error: string) => {
            setErrorMessage(error);
            setSubmitting(false);
          };

          return (
            <div>
              <Form>
                <FormikTextField
                  autoComplete="email"
                  autoFocus
                  fullWidth
                  label="Email"
                  margin="dense"
                  name="email"
                  placeholder="e.g. f.nightengale@gmail.com"
                  type="email"
                />
                <FormikTextField
                  autoComplete="off"
                  fullWidth
                  label="Password"
                  margin="dense"
                  name="password"
                  placeholder="It was at least 6 characters"
                  type="password"
                />
                <FormControl margin="dense" fullWidth variant="standard">
                  <p className="help is-danger">{errorMessage}</p>
                </FormControl>
                <FormControl margin="normal" fullWidth variant="standard">
                  <Grid container spacing={3} alignItems="center">
                    <Grid size={{ xs: 4 }}>
                      <FormikSubmitButton color="primary" shape="rounded">
                        Login
                      </FormikSubmitButton>
                    </Grid>
                    <Grid size={{ xs: 8 }}>
                      <Grid container spacing={3} justifyContent="flex-end">
                        <Grid>
                          <Link href="/reset-password" legacyBehavior>
                            <a>Forgot password</a>
                          </Link>
                        </Grid>
                        <Grid>
                          <Link
                            href={{
                              pathname: '/signup',
                              query: router.query,
                            }}
                            legacyBehavior
                          >
                            <a>Sign up</a>
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </Form>
              <div className="is-divider" data-content="OR" />
              <div className="social-media-button-wrapper-login">
                <FacebookButton
                  onSuccess={handleSSOResult}
                  onFail={handleSSOError}
                />
                <GoogleButton
                  onSuccess={handleSSOResult}
                  onFail={handleSSOError}
                />
                <AppleButton
                  redirectURL={redirectURL}
                  onSuccess={handleSSOResult}
                  onFail={handleSSOError}
                />
                <YahooButton
                  onSuccess={handleSSOResult}
                  onFail={handleSSOError}
                />
              </div>
            </div>
          );
        }}
      </Formik>
      <style jsx>{`
        .social-media-button-wrapper-login {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          gap: 1rem;
        }
        :global(
            .social-media-button-wrapper-login
              .react-apple-signin-auth-btn-dark.apple-auth-btn
          ) {
          width: 100%;
          border-radius: 5rem;
        }

        .is-divider {
          display: block;
          position: relative;
          border-top: 0.1rem solid ${neutralDefault};
          height: 0.1rem;
          margin: 2rem 0;
          text-align: center;
        }

        .is-divider[data-content]::after {
          background: white;
          color: ${neutralHigh};
          content: attr(data-content);
          display: inline-block;
          font-size: 0.75rem;
          padding: 0.5rem 0.8rem;
          transform: translateY(-1.1rem);
          text-align: center;
        }
      `}</style>
    </AuthLayout>
  );
}
