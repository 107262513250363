import { type ReactNode, createContext, useContext, useMemo } from 'react';

import { FeatureFlagsDocument } from '@generated/graphql';
import { LoadingOverlay } from '@src/components/loading-overlay';
import { useQuery } from '@src/hooks/use-graphql-hooks';

// exported only for tests
export const FLAG_DEFAULTS = {
  isAddOtherFacilityEnabled: false,
  nursysLicenseVerificationMultipleStates: false,
  aaClinicianResumeParser: false,
  ffWorkExperienceV2: false,
};

type FeatureFlags = typeof FLAG_DEFAULTS;

// exported only for tests
export const FeatureFlagsContext = createContext(FLAG_DEFAULTS);

export const FeatureFlagsProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const query = useQuery(FeatureFlagsDocument);

  const effectiveFlags = useMemo(
    () => ({
      ...FLAG_DEFAULTS,
      ...((query.data?.featureFlags || {}) as FeatureFlags),
    }),
    [query.data]
  );

  if (query.loading && !query.data) {
    return <LoadingOverlay />;
  }

  return (
    <FeatureFlagsContext.Provider value={effectiveFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
