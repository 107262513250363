import Image from 'next/image';

import { MOBILE_MAX_WIDTH_PX_VALUE } from '@src/constants';
import { useDimensions } from '@src/hooks/use-dimensions';
import { black, neutralHighest } from '@src/support/colors';
import type { ReactNode } from 'react';

type Props = {
  image: string;
  statusCode: number;
  title: ReactNode;
  details: ReactNode;
  actions?: ReactNode;
};

export const BaseError = ({
  image,
  statusCode,
  title,
  details,
  actions,
}: Props) => {
  const {
    ref,
    dimensions: { width },
  } = useDimensions();
  const isContainerBig = width > MOBILE_MAX_WIDTH_PX_VALUE;
  const titleFontSize = isContainerBig ? '2.5rem' : '1.5rem';
  const titleLineHeight = isContainerBig ? '3rem' : '2rem';

  return (
    <div ref={ref} className="BaseError__container">
      <Image src={image} width="150" height="200" alt="Error" />
      <div className="BaseError__title">{title}</div>
      <div className="BaseError__status">Error {statusCode}</div>
      <div className="BaseError__details">{details}</div>
      {actions}
      <style jsx>{`
        .BaseError__container {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: center;
          gap: 1.5rem;
          padding: 6rem 2rem 2rem;
        }

        .BaseError__title {
          color: ${black};
          font-size: ${titleFontSize};
          font-weight: bold;
          line-height: ${titleLineHeight};
        }

        .BaseError__status {
          font-size: 1rem;
          line-height: 1.25rem;
          color: ${neutralHighest};
        }

        .BaseError__details {
          color: ${black};
          line-height: 1.25rem;
          max-width: 30rem;
        }
      `}</style>
    </div>
  );
};
