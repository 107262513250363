import type { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

import { useIsMobile } from '@src/hooks/use-is-mobile';
import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';
import { merge } from 'lodash';

// API docs: https://reactcommunity.org/react-modal/
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    position: 'fixed',
    border: 'none',
  },
  overlay: {
    backgroundColor: rgba(colors.black, 0.5),
    zIndex: 1300,
  },
};

type OwnProps = {
  styles?: ReactModal.Styles;
};

type Props = OwnProps & ReactModal.Props;

export function Modal({
  children,
  isOpen = false,
  onAfterOpen,
  onRequestClose,
  styles = {},
  ...props
}: PropsWithChildren<Props>) {
  const isMobile = useIsMobile();

  ReactModal.setAppElement('body');

  return (
    <ReactModal
      style={merge(
        {
          content: {
            ...modalStyles.content,
            width:
              isMobile && typeof window !== 'undefined'
                ? window.innerWidth - 40
                : 335,
          },
          overlay: {
            ...modalStyles.overlay,
          },
        },
        styles
      )}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      {...props}
    >
      {children}
    </ReactModal>
  );
}
