import { useRouter } from 'next/router';
import {
  type MouseEvent,
  type MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { type AuthSuccessResult, isSSOError, signUpFb } from '@src/apiCalls';
import { primarySignupFormVar } from '@src/apollo/local';
import { RedirectOverlay } from '@src/components/redirect-overlay';
import { SSOButton } from '@src/components/sso-buttons/sso-button';
import { autoFill as squatchAutoFill } from '@src/lib/saasquatch';
import { CookieName, getCookie } from '@src/utils/cookies';
import { getClickAdIds } from '@src/utils/gclid';

// Auth result from the Facebook API
// From https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus#response_and_session_objects
interface FBAuthResult {
  status: 'connected' | 'not_authorized' | 'unknown';
  authResponse: {
    accessToken: string;
    expiresIn: string;
    signedRequest: string;
    userID: string;
  };
}

declare global {
  interface Window {
    FB?: {
      login: (
        callback: (data: FBAuthResult) => void,
        options?: { scope: string }
      ) => void;
      getLoginStatus: (
        callback: (data: FBAuthResult) => void,
        refreshCache?: boolean
      ) => void;
    };
  }
}

type Props = {
  signup?: boolean;
  signupSource?: string;
  referrerCode?: string;
  signupSourceQualifier?: string;
  viewer?: {
    firstName: string;
    preferredName: string;
    lastName: string;
  };
  onClick?: MouseEventHandler;
  onSuccess: (result: AuthSuccessResult) => void;
  onFail?: (error: string) => void;
};

const errorMessage =
  'Could not sign up using Facebook. Please try signing up with your email instead.';

export const FacebookButton = ({
  onClick,
  onSuccess,
  onFail,
  viewer,
  ...props
}: Props) => {
  const router = useRouter();
  const [showLoader, setShowLoader] = useState(false);
  const { nurseProfile } = primarySignupFormVar();

  const handleResult = useCallback(
    async (result: FBAuthResult) => {
      if (result.status !== 'connected') {
        return;
      }

      setShowLoader(true);

      try {
        const { gclid, gbraid, wbraid } = getClickAdIds();
        const referralCode = await squatchAutoFill();
        const referringDomain = getCookie(CookieName.ReferringDomain);
        const results = await signUpFb({
          signup: props.signup,
          facebook_user_id: result.authResponse.userID,
          facebook_access_token: result.authResponse.accessToken,
          signup_source: props.signupSource,
          signup_source_qualifier: props.signupSourceQualifier,
          initial_referring_domain: referringDomain,
          referrer_code: props.referrerCode ? [props.referrerCode] : [],
          referral_code: referralCode ?? undefined,
          first_name: viewer?.firstName || '',
          preferred_name: viewer?.preferredName || '',
          last_name: viewer?.lastName || '',
          role_id: nurseProfile.roleId,
          nurse_type: nurseProfile.nurseType,
          gclid,
          gbraid,
          wbraid,
        });

        setShowLoader(false);

        if (isSSOError(results)) {
          onFail?.(results.sso_error || results.error || errorMessage);
        } else {
          localStorage.setItem('LAST_USED_SSO', 'facebook');
          onSuccess(results);
        }
      } catch {
        // Usually network errors
        setShowLoader(false);
        onFail?.(errorMessage);
      }
    },
    // biome-ignore lint/correctness/useExhaustiveDependencies: keep as is
    [nurseProfile, props, onFail, onSuccess, viewer]
  );

  useEffect(() => {
    // The following code will only execute inside a native in-app browser.
    // https://developers.facebook.com/docs/facebook-login/security/#in-app-browsers-and-the-javascript-sdk
    if (window.FB) {
      if (router.asPath.indexOf('access_token') !== -1) {
        // do not pass async here as FB will break with async functions
        window.FB.getLoginStatus(data => handleResult(data), true);
      }
    }
  }, [router, handleResult]);

  const fbClick = (event: MouseEvent) => {
    const scope = 'public_profile,email';

    if (onClick) {
      onClick(event);
    } else {
      event.preventDefault();
    }

    if (window.FB) {
      // do not pass async here as FB will break with async functions
      window.FB.login(data => handleResult(data), { scope });
    }
  };

  if (showLoader) {
    return <RedirectOverlay message="Loading..." />;
  }

  return <SSOButton provider="facebook" onClick={fbClick} />;
};
