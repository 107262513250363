import type { RefObject } from 'react';

import type { NavigationUserFragment } from '@generated/graphql';
import * as colors from '@src/support/colors';
import { neutralLower } from '@src/support/colors';
import { Avatar, FontAwesomeIcon } from '@src/ui';
import { userDisplayName } from '@src/utils/user-utils';
import { SkeletonLoading } from '../skeleton-loading';

type Props = {
  anchorRef: RefObject<HTMLDivElement>;
  user: NavigationUserFragment | null;
  onClick: () => void;
  open?: boolean;
  clinicianNotNew: boolean;
  loading: boolean;
};

export const NavigationTopBarUser = ({
  loading,
  anchorRef,
  onClick,
  user,
  open,
}: Props) => {
  const displayName = user
    ? userDisplayName(user, { abbreviateLast: true })
    : '';

  return (
    <div className="NavigationTopBarUser" ref={anchorRef} onClick={onClick}>
      <div className="NavigationTopBarUserContent">
        <Avatar
          profileImageUrl={user?.profilePic}
          style={{
            width: 32,
            height: 32,
            border: `1px solid ${colors.neutralDefault}`,
          }}
          alt="Profile Photo"
        />

        {loading ? (
          <SkeletonLoading round={24} width={80} height={16} />
        ) : (
          <div className="NavigationTopBarUserName">
            {displayName}
            <span className="NavigationTopBarUserDown">
              <FontAwesomeIcon
                icon={['fal', open ? 'angle-up' : 'angle-down']}
                fixedWidth
                color={colors.neutralHighest}
              />
            </span>
          </div>
        )}
      </div>
      <style jsx>{`
        .NavigationTopBarUser {
          font-weight: 300;
          cursor: pointer;
          border: ${neutralLower} 1px solid;
          border-radius: 6.25rem;
          padding: 0.5rem 0.375rem;
          height: 2.75rem;
          align-self: center;
        }

        .NavigationTopBarUser:hover {
          background-color: ${colors.accent01Lower};
        }

        .NavigationTopBarUserContent {
          height: 100%;
          display: flex;
          flex-flow: row;
          align-items: center;
          position: relative;
          border-radius: 4px;
          gap: 0.375rem;
        }

        .NavigationTopBarUserName {
          display: block;
          font-size: 1rem;
          color: ${colors.neutralHighest1};
        }

        .NavigationTopBarUserDown {
          font-size: 1rem;
          color: ${colors.neutralHighest1};
          position: relative;
          top: 0.125rem;
        }
      `}</style>
    </div>
  );
};
