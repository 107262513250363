import * as cookie from 'cookie';

export function getAmplitudeSessionIdFromCookieString(cookieString: string) {
  const parsed = cookie.parse(cookieString);

  const sessionIdKey = Object.keys(parsed).find(key =>
    key.includes('amplitude_idundefined')
  );

  return sessionIdKey ? parsed[sessionIdKey] : undefined;
}
