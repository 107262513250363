import {
  IntentFormDataDocument,
  IntentFormModalUpdateStaleIntentDocument,
} from '@generated/graphql';
import { LoadingOverlay } from '@src/components/loading-overlay';
import { DEFAULT_ERROR_MESSAGE } from '@src/components/qualification-questions/constants';
import { JOB_SEARCH_STATUS_MODAL } from '@src/constants/home-page';
import { USER_CLICKED_SAVE_JOB_SEARCH_STATUS_PREFERENCES } from '@src/constants/segment-events/home';
import { useSnackbarMessenger } from '@src/hooks';
import { useMutation, useQuery } from '@src/hooks/use-graphql-hooks';
import { analytics } from '@src/lib/analytics';
import { Modal, ModalAction, ModalActions, ModalHeader, Title } from '@src/ui';
import { Form, Formik } from '@src/ui/formik';
import { dateToGQLDate, dateUtils } from '@src/utils/date';
import { futureDate } from '@src/utils/yup';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import {
  IntentFormFields,
  type IntentFormFieldsValues,
} from './intent-form-fields';
import { getIntentFormInitialValues } from './intent-form-utils';

type Props = { isOpen: boolean; onClose: () => void };

export const IntentFormModal = ({ isOpen = false, onClose }: Props) => {
  const [snackbar] = useSnackbarMessenger();

  const { data, loading } = useQuery(IntentFormDataDocument);
  const [updateNursePreferences] = useMutation(
    IntentFormModalUpdateStaleIntentDocument,
    {
      onCompleted() {
        onClose();
      },
      onError() {
        snackbar.addErrorMessage(DEFAULT_ERROR_MESSAGE);
        onClose();
      },
      refetchQueries: [gql(IntentFormDataDocument.toString())],
    }
  );

  const handleSave = ({ looking, ...values }: IntentFormFieldsValues) => {
    const startPref = looking ? values.startPref : 'not_looking';
    const earliestStartDate =
      startPref === 'specific_date' && values.earliestStartDateAt
        ? dateToGQLDate(values.earliestStartDateAt)
        : null;

    updateNursePreferences({
      variables: {
        input: {
          startPref,
          earliestStartDate,
        },
      },
    });

    analytics.track(USER_CLICKED_SAVE_JOB_SEARCH_STATUS_PREFERENCES, {
      source: JOB_SEARCH_STATUS_MODAL,
    });
  };

  const { earliestStartDateAt, startPref } = getIntentFormInitialValues(data);

  const initialValues: IntentFormFieldsValues = {
    looking: startPref !== 'not_looking',
    startPref: startPref,
    earliestStartDateAt: earliestStartDateAt,
  };

  const validationSchema = Yup.object().shape({
    looking: Yup.string().required('Please select an option.'),
    startPref: Yup.string().when('looking', {
      is: true,
      then: schema => schema.required('Please select an option.'),
    }),
    earliestStartDateAt: futureDate()
      .label('Specific date')
      .when('startPref', {
        is: 'specific_date',
        then: schema =>
          schema
            .required()
            .transform(value => (dateUtils.isValid(value) ? value : undefined))
            .required('Please select a date.'),
        otherwise: schema => schema.nullable(),
      }),
  });

  return (
    <Modal isOpen={isOpen} shouldCloseOnEsc shouldCloseOnOverlayClick>
      <div className="IntentFormModal">
        <ModalHeader onClose={onClose} withCloseButton>
          <Title weight="bold">Update Job Search Status</Title>
        </ModalHeader>
        <p>Personalize your Trusted experience by updating your preferences.</p>

        {loading ? (
          <LoadingOverlay />
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSave}
            validationSchema={validationSchema}
          >
            {({ isSubmitting, isValid }) => (
              <Form>
                <IntentFormFields />
                <ModalActions>
                  <ModalAction
                    disabled={isSubmitting || !isValid}
                    label="Save Preferences"
                    fluid
                  />
                </ModalActions>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <style jsx>{`
        .IntentFormModal {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      `}</style>
    </Modal>
  );
};
