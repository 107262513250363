import {
  type ProfileCompletionSummaryFragment,
  ProfileStatusEnum,
} from '@generated/graphql';
import { getProfileStatus } from '@src/components/profile-completion-widget-v2/profile-status';

export const isProfileBlocked = ({
  user,
  profileCompletionSummary,
}: {
  user: {
    readonly nurseProfile?: { readonly state?: string | null } | null;
  } | null;
  profileCompletionSummary: ProfileCompletionSummaryFragment;
}) => {
  if (!user) return true;

  const isWaitlisted = user.nurseProfile?.state === 'waitlisted';
  const hasProfileCompletedOnce =
    profileCompletionSummary?.completedAt &&
    profileCompletionSummary?.completedAt?.length > 0;

  if (isWaitlisted && hasProfileCompletedOnce) return false;

  const profileStatus = getProfileStatus(profileCompletionSummary);
  const completed = [
    ProfileStatusEnum.Complete,
    ProfileStatusEnum.Expiring,
    ProfileStatusEnum.Pending,
  ].includes(profileStatus);

  return (
    user.nurseProfile?.state !== 'activated' &&
    user.nurseProfile?.state !== 'deactivated' &&
    !completed
  );
};
