import { FontAwesomeIcon } from '@src/ui';
import type { PropsWithChildren } from 'react';
type Props = {
  withCloseButton?: boolean;
  onClose?: () => void;
};

export function ModalHeader({
  children,
  onClose,
  withCloseButton = false,
}: PropsWithChildren<Props>) {
  if (!withCloseButton) {
    return children;
  }

  return (
    <div className="ModalHeader">
      <div>{children}</div>
      <div onClick={onClose}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </div>
      <style jsx>{`
        .ModalHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      `}</style>
    </div>
  );
}
