import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

import { MOBILE_MAX_WIDTH } from '@src/constants';
import * as colors from '@src/support/colors';

type NavBackdropProps = {
  onClose: () => void;
  isOpen?: boolean;
};

type NavSideMenuProps = NavBackdropProps & {};

const NavBackdrop = ({
  children,
  onClose,
  isOpen,
}: PropsWithChildren<NavBackdropProps>) => {
  return (
    <div className={clsx('NavBackdrop', { visible: isOpen })}>
      {children}
      <div className="backdrop" onClick={onClose} />
      <style jsx>{`
        .NavBackdrop .backdrop {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1000;
          visibility: hidden;
          opacity: 0;
          will-change: opacity, transform;
        }

        .NavBackdrop.visible .backdrop {
          visibility: visible;
          opacity: 1;
        }

        .NavBackdrop .backdrop {
          transition:
            opacity 600ms,
            visibility 600ms;
        }
      `}</style>
    </div>
  );
};

export const NavSideMenu = ({
  children,
  isOpen = false,
  onClose,
}: PropsWithChildren<NavSideMenuProps>) => {
  return (
    <NavBackdrop isOpen={isOpen} onClose={onClose}>
      <div className={clsx('NavSideMenu', { visible: isOpen })}>
        {children}
        <style jsx>{`
          .NavSideMenu {
            position: absolute;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            width: 80%;
            max-width: 550px;
            min-width: 300px;
            height: 100%;
            z-index: 1001;
            background-color: ${colors.white};
            visibility: hidden;
            opacity: 0;
            will-change: opacity, transform, visibility;
            transition:
              opacity 300ms,
              transform 300ms,
              visibility 300ms;
            transform: translateX(-100%);
          }

          .NavSideMenu.visible {
            visibility: visible;
            opacity: 1;
            transform: translateX(0%);
          }

          @media screen and (min-width: ${MOBILE_MAX_WIDTH}) {
            .NavSideMenu {
              display: none;
            }
          }
        `}</style>
      </div>
    </NavBackdrop>
  );
};
