import { primary01 } from '@src/support/colors';
import { Avatar as MUIAvatar } from '@src/ui/material-ui';
import type { ComponentProps } from 'react';

type Props = {
  profileImageUrl?: string | null;
  highlight?: boolean;
} & ComponentProps<typeof MUIAvatar>;

export const Avatar = ({
  profileImageUrl,
  highlight,
  style,
  ...props
}: Props) => {
  const highlightStyle = highlight ? { border: `2px solid ${primary01}` } : {};
  return (
    <MUIAvatar
      style={{
        ...style,
        ...highlightStyle,
      }}
      alt="Advocate profile photo"
      src={profileImageUrl ?? '/static/profile/empty-flo.png'}
      {...props}
    />
  );
};
