import { IntentFormModal } from '@src/components/intent-form-modal/intent-form-modal';
import { useEffect, useState } from 'react';

export const IntentFormNavModal = ({
  staleIntent,
}: {
  staleIntent: boolean;
}) => {
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    setModalOpened(staleIntent);
  }, [staleIntent]);

  return (
    <IntentFormModal
      isOpen={modalOpened}
      onClose={() => setModalOpened(false)}
    />
  );
};
