import type { TransactionEvent } from '@sentry/types';

export function isFilteredTransaction(event: TransactionEvent) {
  const traceData = event.contexts?.trace?.data;
  if (!traceData) {
    return false;
  }

  return (
    event.transaction === 'GET /job/[id]' &&
    traceData['http.response.status_code'] === 410
  );
}
