function isCancelledNetworkError(error: Error) {
  return error.name === 'TypeError' && error.message === 'cancelled';
}

function isLoadFailed(error: Error) {
  return (
    error.name === 'TypeError' &&
    (error.message === 'Failed to fetch' || error.message === 'Load failed')
  );
}

export function isFilteredException(error: Error) {
  return isCancelledNetworkError(error) || isLoadFailed(error);
}
