import { LoadingOverlay } from '@src/components/loading-overlay';
import { LoginPage } from '@src/containers/login-page';
import { getUser } from '@src/utils/cookies';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function Login() {
  const [loggedIn, setLoggedIn] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setLoggedIn(!!getUser());
  }, []);

  if (loggedIn) {
    router.replace({
      pathname: '/',
      query: router.query,
    });
  }

  return loggedIn ? <LoadingOverlay /> : <LoginPage />;
}
