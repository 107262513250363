import { useIsMobile } from '@src/hooks/use-is-mobile';
import { Tooltip } from '@src/ui';

export const LastUsedTooltip = (props: {
  provider: string;
  children: React.ReactNode;
}) => {
  const isMobile = useIsMobile();
  const lastUsed =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('LAST_USED_SSO')
      : null;

  return (
    <Tooltip
      open={lastUsed === props.provider}
      placement={isMobile ? 'top' : 'right'}
      title="Last used"
    >
      {props.children}
    </Tooltip>
  );
};
