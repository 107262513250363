import { type ReactNode, useState } from 'react';
import { JobsPageContext } from './jobs-page-context';

import type { JobsPageSearchParams } from './types';

export const JobsPageProvider = ({ children }: { children?: ReactNode }) => {
  const [newQueryParams, setNewQueryParams] = useState<
    JobsPageSearchParams | undefined
  >();

  return (
    <JobsPageContext.Provider value={{ newQueryParams, setNewQueryParams }}>
      {children}
    </JobsPageContext.Provider>
  );
};
