import { type SnackbarMessage, snackbarsVar } from '@src/apollo/local';
import type { ReactNode } from 'react';
import { v4 } from 'uuid';

export class SnackbarMessenger {
  static defaultOptions() {
    return {
      autoHideDuration: 3000,
      createdAt: new Date(),
      id: v4(),
      close: true,
    };
  }

  addSuccessMessage(
    message: ReactNode,
    overrides: Partial<Omit<SnackbarMessage, 'color'>> = {}
  ) {
    this.addMessage(message, { color: 'success', ...overrides });
  }

  addWarningMessage(
    message: ReactNode,
    overrides: Partial<Omit<SnackbarMessage, 'color'>> = {}
  ) {
    this.addMessage(message, { color: 'warning', ...overrides });
  }

  addErrorMessage(
    message: ReactNode,
    overrides: Partial<Omit<SnackbarMessage, 'color'>> = {}
  ) {
    this.addMessage(message, { color: 'error', ...overrides });
  }

  addInfoMessage(
    message: ReactNode,
    overrides: Partial<Omit<SnackbarMessage, 'color'>> = {}
  ) {
    this.addMessage(message, { color: 'info', ...overrides });
  }

  private addMessage(
    message: ReactNode,
    overrides: Partial<SnackbarMessage> = {}
  ) {
    snackbarsVar([
      ...snackbarsVar(),
      {
        ...SnackbarMessenger.defaultOptions(),
        message,
        color: 'info', // just needed as a default
        ...overrides,
      },
    ]);
  }
}
