import {
  type ApolloCache,
  type DefaultContext,
  type MutationHookOptions,
  type OperationVariables,
  type QueryHookOptions,
  gql,
  useLazyQuery as useApolloLazyQuery,
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
} from '@apollo/client';
import type {
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  NoInfer,
} from '@apollo/client/react/types/types';
import type { TypedDocumentString } from '@generated/graphql';

// Just wrappers for
// * isolate apollo usage
// * support for typed doc string (for bundle size optimization)
// more on: https://github.com/apollographql/apollo-feature-requests/issues/390

export function useQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentString<TData, TVariables>,
  options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>
) {
  return useApolloQuery<TData, TVariables>(gql(query.toString()), options);
}

export function useLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: TypedDocumentString<TData, TVariables>,
  options?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>
): LazyQueryResultTuple<TData, TVariables> {
  return useApolloLazyQuery<TData, TVariables>(gql(query.toString()), options);
}

export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: TypedDocumentString<TData, TVariables>,
  options?: MutationHookOptions<
    NoInfer<TData>,
    NoInfer<TVariables>,
    TContext,
    TCache
  >
) {
  return useApolloMutation(gql(mutation.toString()), options);
}
