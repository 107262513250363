import * as colors from '@src/support/colors';
import {
  Button,
  type ButtonColors,
  type ButtonSizes,
  type ButtonVariants,
} from '../button';

type ColorProps = (typeof colors)[keyof typeof colors];

type Props = {
  label: string;
  onClick?: () => void;
  variant?: ButtonVariants;
  color?: ButtonColors;
  size?: ButtonSizes;
  underline?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  textColor?: ColorProps;
};

export function ModalAction({
  label,
  onClick,
  variant = 'default',
  color = 'primary',
  size = 'medium',
  underline,
  disabled,
  fluid,
  textColor = colors.black,
}: Props) {
  return (
    <Button
      className="ModalAction"
      onClick={onClick}
      shape="rounded"
      color={color}
      variant={variant}
      underline={underline}
      disabled={disabled}
      fluid={fluid}
      size={size}
      type="submit"
      style={{
        color: textColor,
      }}
    >
      {label}
    </Button>
  );
}
