import { type ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const useIsomorphicEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export function Portal(props: { children?: ReactNode }) {
  const [container, setContainer] = useState<HTMLElement>();

  useIsomorphicEffect(() => {
    setContainer(document.body);
  }, []);
  return container
    ? createPortal(props.children, container, 'Portal')
    : container || null;
}
