import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';
import { makeStyles } from '@src/ui/material-ui';
import clsx, { type ClassValue } from 'clsx';
import type { JSX, PropsWithChildren } from 'react';

export const PANEL_HEADER_HEIGHT = '3.75rem';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    height: PANEL_HEADER_HEIGHT,
    minHeight: PANEL_HEADER_HEIGHT,
    boxShadow: `${rgba(colors.black, 0.15)} 0px 0.0625em 0.1875em 0`,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
});

interface OwnProps {
  sticky?: boolean;
  className?: ClassValue;
}

type Props = PropsWithChildren<OwnProps> & JSX.IntrinsicElements['header'];

export const PanelHeader = ({
  children,
  className,
  sticky,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <header
      className={clsx(classes.root, { [classes.sticky]: sticky }, className)}
      {...props}
    >
      {children}
    </header>
  );
};
