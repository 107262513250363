import { Button } from '@src/ui';
import { useFormikContext } from '@src/ui/formik';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

type Props = ComponentProps<typeof Button>;

export const FormikSubmitButton = forwardRef<HTMLButtonElement, Props>(
  ({ disabled, ...props }, ref) => {
    const formik = useFormikContext();

    return (
      <Button
        ref={ref}
        disabled={formik.isSubmitting || disabled}
        type="submit"
        {...props}
      />
    );
  }
);
