import type { ReactNode } from 'react';

export function Scroll(props: Required<{ children?: ReactNode }>) {
  return (
    <div className="Scroll">
      {props.children}
      <style jsx>{`
        .Scroll {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          position: relative;
        }
      `}</style>
    </div>
  );
}
