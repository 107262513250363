import { useWarning } from '@src/contexts/warning-provider/warning-provider';
import * as colors from '@src/support/colors';
import { type FieldHookConfig, useField } from '@src/ui/formik';
import {
  TextField,
  type TextFieldProps,
  type TextFieldVariants,
} from '@src/ui/material-ui';

type Props = FieldHookConfig<string> & { variant?: TextFieldVariants } & Omit<
    TextFieldProps,
    'variant'
  >;

/**
 * @formValueType `string`, or the type of the `type` property
 */
export function FormikTextField(props: Props) {
  const [field, meta] = useField(props);
  const { required, variant = 'standard', ...remainingProps } = props;

  const { getWarning } = useWarning();
  const warning = getWarning(props.name);

  const helperText = () => {
    if (!!meta.error && meta.touched) {
      return meta.error;
    }

    if (warning) {
      return <span style={{ color: colors.semanticWarning }}>{warning}</span>;
    }

    return props.helperText;
  };

  const labelColor = () => {
    if (meta.error && meta.touched) {
      return { color: colors.semanticError };
    }

    if (warning && meta.touched) {
      return { color: colors.semanticWarning };
    }

    return {};
  };

  return (
    <>
      <TextField
        {...remainingProps}
        {...field}
        label={
          props.label && (
            <>
              <span style={labelColor()}>{props.label}</span>
              {required && (
                <span className="FormikTextField--required"> *</span>
              )}
            </>
          )
        }
        InputProps={{ id: props.name, ...props.InputProps }}
        SelectProps={{ id: props.name, ...props.SelectProps }}
        InputLabelProps={{ htmlFor: props.name, ...props.InputLabelProps }}
        error={!!meta.error && meta.touched}
        helperText={helperText()}
        variant={variant}
      />
      <style jsx>{`
        .FormikTextField--required {
          color: ${colors.semanticError};
        }
        .MuiInput-underline:after {
          ${warning ? `border-bottom-color: ${colors.semanticWarning};` : ''}
        }
      `}</style>
    </>
  );
}
