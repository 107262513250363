import { useWarning } from '@src/contexts/warning-provider/warning-provider';
import * as colors from '@src/support/colors';
import { type FieldMetaProps, useField } from '@src/ui/formik';
import {
  type DatePickerProps,
  type DateValidationError,
  DesktopDatePicker,
  type PickerChangeHandlerContext,
  type PickerValidDate,
  type TextFieldProps,
} from '@src/ui/material-ui';
import { dateUtils } from '@src/utils/date';
import clsx from 'clsx';
import type { ReactNode } from 'react';

type Props = Pick<
  DatePickerProps<PickerValidDate>,
  | 'disableFuture'
  | 'disablePast'
  | 'disabled'
  | 'format'
  | 'label'
  | 'maxDate'
  | 'minDate'
  | 'onChange'
  | 'openTo'
  | 'referenceDate'
  | 'sx'
  | 'views'
> &
  Pick<
    TextFieldProps,
    | 'fullWidth'
    | 'InputLabelProps'
    | 'inputProps'
    | 'margin'
    | 'placeholder'
    | 'required'
    | 'variant'
  > & {
    name: string;
    labelShrink?: boolean;
    inputMode?: string;
    inputClassName?: string;
  };

export const FormikDatePicker = ({
  disableFuture,
  disablePast,
  disabled,
  format = 'MM/dd/yyyy',
  fullWidth,
  InputLabelProps, // TODO: deprecated, update to new api
  inputClassName,
  inputMode,
  inputProps, // TODO: deprecated, update to new api
  label,
  labelShrink,
  margin,
  maxDate,
  minDate,
  name,
  onChange,
  openTo,
  placeholder,
  referenceDate,
  required = false,
  sx,
  variant = 'standard',
  views = ['month', 'year', 'day'],
}: Props) => {
  const [field, meta, helpers] = useField<PickerValidDate | null>({
    name: name,
  });

  const { getWarning } = useWarning();
  const warning = getWarning(name);

  const getHelperText = () => {
    if (!!meta.error && meta.touched) {
      return meta.error;
    }

    if (warning) {
      return <span style={{ color: colors.semanticWarning }}>{warning}</span>;
    }

    return '';
  };

  const textFieldProps: TextFieldProps = {
    id: name,
    slotProps: {
      htmlInput: {
        className: inputClassName,
        inputMode,
      },
      inputLabel: {
        shrink: labelShrink,
      },
    },
    placeholder,
    margin,
    error: !!meta.error && meta.touched,
    helperText: getHelperText(),
    variant,
    fullWidth,
    onBlur: () => helpers.setTouched(true),
    InputLabelProps,
    inputProps,
  };

  const handleChange = (
    date: PickerValidDate | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    let thisDate = date;

    if (thisDate && dateUtils.isValid(thisDate)) {
      thisDate = dateUtils.adjustDateForUTC(thisDate);
      if (!views.includes('day')) {
        // In this case the picked date will default
        // to the current day of the month, so if e.g. it's march 31st
        // and the user picks february, an invalid date would come out.
        thisDate.setDate(1);
      }
    }

    // we must touch before set the value
    // otherwise the validation will be triggered with the previous state
    helpers.setTouched(true);
    helpers.setValue(thisDate);
    onChange?.(thisDate, context);
  };

  // Using DesktopDatePicker because the responsive DatePicker does not play
  // well with tests and breaks our e2e test suit on CI.
  // https://mui.com/x/react-date-pickers/base-concepts/#testing-caveats
  return (
    <>
      <DesktopDatePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabled={disabled}
        format={format}
        label={
          label && (
            <FormikDatePickerLabel
              label={label}
              meta={meta}
              required={required}
              warning={warning}
            />
          )
        }
        maxDate={maxDate}
        minDate={minDate ?? undefined}
        name={name}
        onChange={handleChange}
        openTo={openTo}
        referenceDate={referenceDate}
        slotProps={{
          textField: textFieldProps,
        }}
        sx={sx}
        value={field.value}
        views={views}
      />
    </>
  );
};

const FormikDatePickerLabel = ({
  label,
  meta,
  required,
  warning,
}: {
  meta: FieldMetaProps<PickerValidDate | null>;
  warning: string;
  label: ReactNode | undefined;
  required: boolean | undefined;
}) => (
  <>
    <span
      className={clsx('', {
        'FormikDatePicker--required': meta.error && meta.touched,
        'FormikDatePicker--warning': warning && meta.touched,
      })}
    >
      {label}
    </span>
    {required && <span className="FormikDatePicker--required"> *</span>}
    <style jsx>{`
      .FormikDatePicker--required {
        color: ${colors.semanticError};
      }

      .FormikDatePicker--warning {
        color: ${colors.semanticWarning};
      }
    `}</style>
  </>
);
