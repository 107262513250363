import * as colors from '@src/support/colors';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

type SizeProps = 'h1' | 'h2' | 'h3' | 'h4';
type WeightProps = 'extraBold' | 'bold' | 'semiBold' | 'regular';
type AlignmentProps = 'left' | 'center' | 'right' | 'inline';

type ColorProps = keyof typeof colors;

type Config = {
  color?: ColorProps;
  align?: AlignmentProps;
  size?: SizeProps;
  weight?: WeightProps;
};

type Props = PropsWithChildren<Config>;

// Figma: https://www.figma.com/design/A5MIQ4LElRMzA0sL8W6CNp/MPDS---Foundations?node-id=220-521&node-type=frame&t=EkYUafIVZIEwrRoQ-0
export const Title = ({
  children,
  color = 'black',
  align = 'left',
  size = 'h3',
  weight = 'regular',
}: Props) => {
  const enabledStyles = buildConfiguration({
    align,
    weight,
  });
  const props = {
    className: clsx('Title', enabledStyles),
    style: { color: colors[color] },
  };
  return (
    <>
      {size === 'h1' ? (
        <h1 {...props}>{children}</h1>
      ) : size === 'h2' ? (
        <h2 {...props}>{children}</h2>
      ) : size === 'h3' ? (
        <h3 {...props}>{children}</h3>
      ) : size === 'h4' ? (
        <h4 {...props}>{children}</h4>
      ) : null}
      <style jsx global>{`
        h1.Title {
          font-size: 2rem;
          line-height: 2.5rem;
        }

        h2.Title {
          font-size: 1.5rem;
          line-height: 2rem;
        }

        h3.Title {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }

        h4.Title {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }

        .Title {
          user-select: none;

          &.weight-extraBold {
            font-weight: 900;
          }

          &.weight-bold {
            font-weight: 700;
          }

          &.weight-semiBold {
            font-weight: 600;
          }

          &.weight-regular {
            font-weight: 400;
          }

          &.align-left {
            text-align: left;
          }

          &.align-center {
            text-align: center;
          }

          &.align-right {
            text-align: center;
          }

          &.align-inline {
            display: inline;
          }
        }
      `}</style>
    </>
  );
};

const buildConfiguration = (props: Config) => {
  return Object.entries(props).reduce<string[]>((acc, [key, value]) => {
    return [...acc, `${key}-${value}`];
  }, []);
};
