import type { ReactNode } from 'react';

import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';

export const NavSidebarCard = ({ children }: { children?: ReactNode }) => (
  <div className="NavSidebarCard">
    {children}
    <style jsx>{`
      .NavSidebarCard {
        background-color: ${colors.white};
        box-shadow: ${rgba(colors.black, 0.05)} 0.125em 0 0 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        transition-duration: 0.3s;
        transition-property: transform, max-width;
        transition-timing-function: ease-in-out;
        width: 100%;
        overflow: hidden;
        transform: translate(0);
      }
    `}</style>
  </div>
);
