import { ArticleContentDocument } from '@generated/graphql';
import { SEGMENT_EVENTS } from '@src/constants';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { analytics } from '@src/lib/analytics';
import { notifyErrorCallback } from '@src/lib/error-reporter';
import { neutralLower, semanticInfoHigh } from '@src/support/colors';
import { type Dispatch, type SetStateAction, useEffect } from 'react';
import { HelpcenterFooter } from './helpcenter-footer';
import type { ArticleContentItem } from './types';

type Props = {
  setHeaderTitle: (title: string) => void;
  setLoading: (loading: boolean) => void;
  backToConversationList: () => void;
  backStep: () => void;
  setShowFeedbackForm: Dispatch<SetStateAction<boolean>>;
  showFeedbackForm: boolean;
  articleId: string;
  setArticleOpen: Dispatch<SetStateAction<boolean>>;
};

export function HelpcenterArticle({
  setHeaderTitle,
  backToConversationList,
  backStep,
  setShowFeedbackForm,
  showFeedbackForm,
  articleId,
  setLoading,
  setArticleOpen,
}: Props) {
  const { data, loading } = useQuery(ArticleContentDocument, {
    variables: { id: articleId },
    onError: notifyErrorCallback('Article Selection - Intercom articles list'),
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setHeaderTitle('Help Article');
  }, [setHeaderTitle]);

  useEffect(() => setLoading(loading), [loading, setLoading]);

  const handleYes = () => {
    analytics.track(SEGMENT_EVENTS.CHAT_PANEL.HELP_ARTICLE_WAS_HELPFUL, {
      isHelpful: true,
      articleName: data?.article.title,
      articleId: articleId,
    });
    setShowFeedbackForm(false);
  };

  const handleNo = () => {
    analytics.track(SEGMENT_EVENTS.CHAT_PANEL.HELP_ARTICLE_WAS_HELPFUL, {
      isHelpful: false,
      articleName: data?.article.title,
      articleId: articleId,
    });
    backStep();
    setShowFeedbackForm(false);
    setArticleOpen(false);
  };

  const handleBackToMessages = () => {
    backToConversationList();
    setArticleOpen(false);
  };

  return (
    <div>
      <div className="container">
        <p
          className="articleTitle"
          dangerouslySetInnerHTML={{
            __html: data?.article.title as ArticleContentItem['title'],
          }}
        />
        <p
          className="articleDesc"
          dangerouslySetInnerHTML={{
            __html: data?.article
              .description as ArticleContentItem['description'],
          }}
        />
        <div
          className="articleBody"
          dangerouslySetInnerHTML={{
            __html: data?.article.body as ArticleContentItem['body'],
          }}
        />
      </div>
      <div className="footerContainer">
        <HelpcenterFooter
          title={
            showFeedbackForm
              ? 'Did this article answer your question?'
              : 'Thanks for the feedback!'
          }
          showFeedbackForm={showFeedbackForm}
          handleYes={handleYes}
          handleNo={handleNo}
          handleBackToMessages={handleBackToMessages}
        />
      </div>
      <style jsx>{`
        .container {
          height: 100%;
          width: 100%;
          padding-bottom: 6.25em;
        }

        :global(.articleTitle) {
          font-size: 1.5em;
          font-weight: 700;
          margin-bottom: 1em;
          line-height: 1.25em;
        }

        :global(.articleDesc) {
          font-size: 1em;
          font-weight: 700;
          margin-bottom: 1.5em;
        }

        :global(.articleBody > p) {
          margin-bottom: 1em;
        }

        :global(.articleBody > p > a) {
          color: ${semanticInfoHigh};
        }

        :global(.articleBody > h1) {
          font-weight: 700;
          padding-bottom: 1em;
        }

        :global(.articleBody > h2) {
          font-weight: 700;
          padding-bottom: 0.5em;
        }

        :global(.articleBody > ul) {
          padding-left: 1em;
          list-style-type: disc;
        }

        :global(.articleBody > ul > li) {
          margin-bottom: 1em;
        }

        :global(.articleBody > ul > li > p > a) {
          color: ${semanticInfoHigh};
        }

        :global(.articleBody > div > img) {
          margin-bottom: 0.5em;
        }

        .footerContainer {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          background-color: ${neutralLower};
        }
      `}</style>
    </div>
  );
}
