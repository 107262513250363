import { neutralHigh, neutralLower } from '@src/support/colors';
import clsx from 'clsx';

type Props = {
  height?: number;
  width?: number;
  round?: number;
};

export const SkeletonLoading = ({
  height = 16,
  width = 0,
  round = 0,
}: Props) => {
  const style = clsx('SkeletonLoading', {
    'SkeletonLoading--Height': height > 0,
    'SkeletonLoading--Width': width > 0,
    'SkeletonLoading--Width-Full': width === 0,
    'SkeletonLoading--Round': round > 0,
  });

  return (
    <>
      <div className={style} />
      <style jsx>{`
        .SkeletonLoading {
          animation: skeleton-loading 1s linear infinite alternate;
        }

        .SkeletonLoading--Height {
          height: ${`${height / 16}rem`};
        }

        .SkeletonLoading--Width {
          width: ${`${width / 16}rem`};
        }

        .SkeletonLoading--Width-Full {
          width: 100%;
        }

        .SkeletonLoading--Round {
          border-radius: ${`${round / 16}rem`};
        }

        @keyframes skeleton-loading {
          from {
            background-color: ${neutralLower};
          }
          to {
            background-color: ${neutralHigh};
          }
        }
      `}</style>
    </>
  );
};
