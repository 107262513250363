import * as colors from '@src/support/colors';
import clsx from 'clsx';
import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ElementType,
  type HTMLAttributes,
  type PropsWithChildren,
  forwardRef,
} from 'react';

import { rgba } from '@src/support/rgba';

export type ButtonColors =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'facebook'
  | 'google'
  | 'apple'
  | 'yahoo'
  | 'cta'
  | 'white';
export type ButtonSizes =
  | 'tiny'
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'medium-tall';
export type ButtonShapes = 'rounded' | 'rectangle';
export type ButtonVariants =
  | 'default'
  | 'icon'
  | 'hollow'
  | 'white'
  | 'link'
  | 'hollowWhite'
  | 'paragraph';
export type ButtonBorders = 'transparentOnHover';
export interface ButtonProps {
  color?: ButtonColors;
  component?: ElementType | string;
  disabled?: boolean;
  fluid?: boolean;
  shape?: ButtonShapes;
  size?: ButtonSizes;
  variant?: ButtonVariants;
  underline?: boolean;
  visible?: boolean;
  border?: ButtonBorders;
}

// There's probably a way to use discriminated unions to prevent defining properties
// from more than one component type, but this is good enough.
type Props = PropsWithChildren<
  ButtonProps &
    (
      | ButtonHTMLAttributes<HTMLButtonElement>
      | AnchorHTMLAttributes<HTMLAnchorElement>
      | HTMLAttributes<HTMLDivElement>
    )
>;

export const Button = forwardRef(
  (
    {
      children,
      className,
      color,
      component: Component = 'button',
      fluid = false,
      shape = 'rectangle',
      size = 'medium',
      variant = 'default',
      visible = true,
      border,
      disabled = false,
      underline = false,
      ...props
    }: Props,
    ref
  ) => (
    <Component
      ref={ref}
      tabIndex={disabled ? -1 : props.tabIndex}
      disabled={disabled}
      className={clsx(
        'Button',
        {
          'Button--color-secondary': color === 'secondary',
          'Button--color-facebook': color === 'facebook',
          'Button--color-apple': color === 'apple',
          'Button--color-yahoo': color === 'yahoo',
          'Button--color-primary': color === 'primary',
          'Button--color-white': color === 'white',
          'Button--color-cta': color === 'cta',
          'Button--color-danger': color === 'danger',
          'Button--disabled': disabled,
          'Button--fluid': fluid,
          'Button--inline': !fluid,
          'Button--invisible': !visible,
          'Button--transparent-border-on-hover':
            border === 'transparentOnHover',
          'Button--underlined': variant === 'link' || underline,
          'Button--shape-rectangle': shape === 'rectangle',
          'Button--shape-rounded': shape === 'rounded',
          'Button--size-medium-tall': size === 'medium-tall',
          'Button--size-large': size === 'large',
          'Button--size-medium': size === 'medium',
          'Button--size-small': size === 'small',
          'Button--size-tiny': size === 'tiny',
          'Button--size-x-small': size === 'x-small',
          'Button--variant-default': variant === 'default',
          'Button--variant-hollow': variant === 'hollow',
          'Button--variant-white': variant === 'white',
          'Button--variant-link': variant === 'link',
          'Button--variant-icon': variant === 'icon',
          'Button--variant-hollow-white': variant === 'hollowWhite',
          'Button--variant-paragraph': variant === 'paragraph',
        },
        className
      )}
      {...props}
    >
      {children}
      <style jsx>{`
        .Button {
          -webkit-tap-highlight-color: transparent;
          appearance: none;
          cursor: pointer;
          font-family: inherit;
          font-weight: bold;
          text-align: center;
          transition-duration: 0.1s;
          transition-property: background-color, color, border-color;
          transition-timing-function: ease-out;
          white-space: nowrap;
          text-decoration: none;
        }

        .Button--color-primary,
        .Button--color-primary:active {
          background-color: ${colors.primary01};
        }

        .Button--color-primary:hover,
        .Button--color-primary:focus {
          background-color: ${rgba(colors.primary01, 0.8)};
        }

        .Button--color-secondary,
        .Button--color-secondary:active {
          background-color: ${colors.white};
        }

        .Button--color-secondary:hover,
        .Button--color-secondary:focus {
          background-color: ${colors.neutralLow};
        }

        .Button--color-danger,
        .Button--color-danger:active {
          background-color: ${colors.semanticErrorHigh};
          color: ${colors.white};
        }

        .Button--color-white,
        .Button--color-white:active {
          background-color: ${colors.white};
        }

        .Button--color-cta,
        .Button--color-cta:active {
          background-color: ${colors.semanticInfo};
          color: ${colors.white};
        }

        .Button--color-apple {
          background-color: ${colors.colorApple};
          color: ${colors.white};
        }

        .Button--color-facebook {
          background-color: ${colors.colorFacebook};
          color: ${colors.white};
        }

        .Button--color-yahoo {
          background-color: ${colors.colorYahoo};
          color: ${colors.white};
        }

        .Button--size-small {
          font-size: 0.8125rem;
          line-height: 1em;
          padding-bottom: 0.7692307692307693em;
          padding-left: 1.1538461538461537em;
          padding-right: 1.1538461538461537em;
          padding-top: 0.7692307692307693em;
        }

        .Button--size-x-small {
          font-size: 0.75rem;
          padding-bottom: 0.3125rem;
          padding-left: 0.625rem;
          padding-right: 0.625rem;
          padding-top: 0.3125rem;
        }

        .Button--size-tiny {
          font-size: 0.75rem;
          line-height: 1em;
          padding-bottom: 0.3rem;
          padding-left: 0.375rem;
          padding-right: 0.375rem;
          padding-top: 0.3rem;
        }

        .Button--size-medium {
          font-size: 1rem;
          line-height: 1.25em;
          padding-bottom: 0.75em;
          padding-left: 1.5em;
          padding-right: 1.5em;
          padding-top: 0.75em;
        }

        .Button--size-large {
          font-size: 1.25rem;
          line-height: 1.3em;
          padding-bottom: 0.7em;
          padding-left: 1.3em;
          padding-right: 1.3em;
          padding-top: 0.7em;
        }

        .Button--size-medium-tall {
          font-size: 0.875rem;
          line-height: 1em;
          padding-bottom: 1.4285714285714286em;
          padding-left: 0.8571428571428571em;
          padding-right: 0.8571428571428571em;
          padding-top: 1.4285714285714286em;
          height: 100%;
        }

        .Button--fluid > .Button--size-medium-tall {
          border-color: transparent;
        }

        .Button > :global(svg + span) {
          margin-left: 0.5rem;
        }

        .Button--variant-default {
          border-color: transparent;
          border-style: solid;
          border-width: 2px;
        }

        .Button--variant-default:hover,
        .Button--variant-default:focus,
        .Button--variant-default:active {
          border-color: transparent;
          border-style: solid;
          border-width: 2px;
        }

        .Button--shape-rounded,
        .Button--shape-rounded:hover,
        .Button--shape-rounded:focus,
        .Button--shape-rounded:active {
          border-radius: 5rem;
          border-width: 1px;
        }

        .Button--variant-white,
        .Button--variant-white:active {
          background-color: ${colors.white};
          border-color: ${colors.neutralDefault};
          border-style: solid;
          border-width: 1px;
        }

        .Button--variant-hollow,
        .Button--variant-hollow:active {
          background-color: transparent;
          border-color: ${colors.neutralDefault};
          border-style: solid;
          border-width: 1px;
        }

        .Button--variant-hollow:hover,
        .Button--variant-hollow:focus {
          background-color: transparent;
          border-color: ${colors.black};
        }

        .Button--variant-hollow-white,
        .Button--variant-hollow-white:focus {
          background-color: transparent;
          border-color: transparent;
          border-style: solid;
          border-width: 2px;
          color: ${colors.neutralHighest1};
        }

        .Button--variant-hollow-white:hover,
        .Button--variant-hollow-white:active {
          background-color: transparent;
          border-color: ${colors.neutralHighest1};
        }

        .Button--disabled,
        .Button--disabled:hover,
        .Button--disabled:focus,
        .Button--disabled:active {
          background-color: ${colors.neutralDefault};
          border-color: ${colors.neutralDefault};
          border-style: solid;
          cursor: default;
          color: ${colors.neutralHighest};
        }

        .Button--disabled.Button--shape-rounded,
        .Button--disabled.Button--shape-rounded:hover,
        .Button--disabled.Button--shape-rounded:focus,
        .Button--disabled.Button--shape-rounded:active {
          background-color: ${colors.neutralLower};
          color: ${colors.neutralHigh};
        }

        .Button--variant-link,
        .Button--variant-link:hover,
        .Button--variant-link:focus,
        .Button--variant-link:active {
          background-color: transparent;
          border-color: transparent;
          color: ${colors.neutralHighest1};
          padding: 0;
        }

        .Button--variant-link:hover {
        }

        .Button--variant-paragraph,
        .Button--variant-paragraph:hover,
        .Button--variant-paragraph:focus,
        .Button--variant-paragraph:active {
          background-color: transparent;
          border: none;
          color: inherit;
          font-size: 1em;
          font-weight: inherit;
          text-decoration: underline;
          padding: 0;
        }

        .Button--variant-icon,
        .Button--variant-icon:hover,
        .Button--variant-icon:focus,
        .Button--variant-icon:active {
          background-color: transparent;
          border-color: transparent;
          color: ${colors.neutralHighest1};
          font-size: 1rem;
          padding-bottom: 0.3125em;
          padding-left: 0;
          padding-right: 0;
          padding-top: 0.3125em;
        }

        .Button--inline {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .Button--fluid {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
        }

        .Button--invisible {
          visibility: hidden;
        }

        .Button--underlined {
          text-decoration: underline;
        }

        .Button--transparent-border-on-hover:hover,
        .Button--transparent-border-on-hover:active {
          border-color: transparent;
        }
      `}</style>
    </Component>
  )
);

Button.displayName = 'Button';
