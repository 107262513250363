import { Tooltip as MuiTooltip } from '@mui/material';
import * as colors from '@src/support/colors';
import type { PropsWithChildren, ReactElement } from 'react';

type Props = {
  title: React.ReactNode;
  onClose?: () => void;
  open?: boolean;
  disableInteractive?: boolean;
  disableFocusListener?: boolean;
  disableHoverListener?: boolean;
  disableTouchListener?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

export function Tooltip({
  title,
  children,
  open,
  placement = 'bottom',
  onClose,
  disableInteractive,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
}: PropsWithChildren<Props>) {
  return (
    <MuiTooltip
      open={open}
      title={title}
      placement={placement}
      onClose={onClose}
      disableInteractive={disableInteractive}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: colors.neutralHighest,
            color: colors.white,
            padding: '0.5rem',
            borderRadius: '0.25em',
            fontSize: 'inherit',
          },
        },
        arrow: {
          sx: {
            '&::before': {
              backgroundColor: colors.neutralHighest,
            },
          },
        },
      }}
      arrow
    >
      {children as ReactElement}
    </MuiTooltip>
  );
}
