// ============================================================================
// Polished Library Removal - Ported Constants, Interfaces, Functions
//
// These functions and declarations may be renamed/relocated after
// library removal.
// ============================================================================
const hexRegex = /^#[a-fA-F0-9]{6}$/;
const hexRgbaRegex = /^#[a-fA-F0-9]{8}$/;
const reducedHexRegex = /^#[a-fA-F0-9]{3}$/;
const reducedRgbaHexRegex = /^#[a-fA-F0-9]{4}$/;

interface rgbaComponents {
  red: number;
  green: number;
  blue: number;
  alpha?: number;
}

/**
 * Port of Polished `rgba` function, logically aligned with Trusted usage.
 */
export function rgba(
  firstValue: number | string,
  secondValue?: number,
  thirdValue?: number,
  fourthValue?: number
): string {
  const hasThirdValue = typeof thirdValue === 'number';
  const hasFourthValue = typeof fourthValue === 'number';
  if (hasThirdValue && hasFourthValue) {
    return `rgba(${firstValue},${secondValue},${thirdValue},${fourthValue})`;
  }

  if (hasThirdValue) {
    return `rgba(${firstValue},${secondValue},${thirdValue})`;
  }

  if (typeof firstValue !== 'string') {
    throw new Error(
      'expected hex color string for `rgba` with 1 or 2 parameters - none provided'
    );
  }

  const hexColor = firstValue.trim();

  // otherwise assume (a) the first value is a hex color string and (b) the second value is the alpha (if present)
  const alphaOverrideValue = secondValue;
  const hasAlphaOverrideValue = typeof secondValue === 'number';

  let components: rgbaComponents | undefined;
  if (hexColor.match(hexRegex)) {
    components = {
      red: Number.parseInt(`${hexColor[1]}${hexColor[2]}`, 16),
      green: Number.parseInt(`${hexColor[3]}${hexColor[4]}`, 16),
      blue: Number.parseInt(`${hexColor[5]}${hexColor[6]}`, 16),
    };
  } else if (hexColor.match(hexRgbaRegex)) {
    // alpha is included as part of hex
    const alpha = Number.parseFloat(
      (Number.parseInt(`${hexColor[7]}${hexColor[8]}`, 16) / 255).toFixed(2)
    );
    components = {
      red: Number.parseInt(`${hexColor[1]}${hexColor[2]}`, 16),
      green: Number.parseInt(`${hexColor[3]}${hexColor[4]}`, 16),
      blue: Number.parseInt(`${hexColor[5]}${hexColor[6]}`, 16),
      alpha: alpha,
    };
  } else if (hexColor.match(reducedHexRegex)) {
    components = {
      red: Number.parseInt(`${hexColor[1]}${hexColor[1]}`, 16),
      green: Number.parseInt(`${hexColor[2]}${hexColor[2]}`, 16),
      blue: Number.parseInt(`${hexColor[3]}${hexColor[3]}`, 16),
    };
  } else if (hexColor.match(reducedRgbaHexRegex)) {
    // alpha is included as part of hex
    const alpha = Number.parseFloat(
      (Number.parseInt(`${hexColor[4]}${hexColor[4]}`, 16) / 255).toFixed(2)
    );

    components = {
      red: Number.parseInt(`${hexColor[1]}${hexColor[1]}`, 16),
      green: Number.parseInt(`${hexColor[2]}${hexColor[2]}`, 16),
      blue: Number.parseInt(`${hexColor[3]}${hexColor[3]}`, 16),
      alpha: alpha,
    };
  }

  if (components) {
    if (hasAlphaOverrideValue) {
      components.alpha = alphaOverrideValue;
    }

    if (components.alpha) {
      return `rgba(${components.red},${components.green},${components.blue},${components.alpha})`;
    }
    return `rgba(${components.red},${components.green},${components.blue})`;
  }

  throw new Error(
    `expected valid hex color string, instead received: ${JSON.stringify(
      hexColor
    )}`
  );
}
