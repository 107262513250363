import * as Sentry from '@sentry/nextjs';
import type { Context as SentryContext } from '@sentry/types';
import type { CookieUser } from '@src/utils/cookies/user';

enum Severity {
  WARNING = 'warning',
}

export type NotifiableError = Error | string;

type ExtraSections = Record<string, SentryContext>;

type Context = string;

type NotifierOptions = {
  // Sentry has setExtra, which is deprecated in favor of setContext and is not
  // the same as this. We already use the 'context', prop, so we can't use it
  // for the same purpose.
  extraSections?: ExtraSections;
  context?: Context;
  severity?: Severity;
  groupBy?: string;
  user?: CookieUser;
};

export function notifyErrorCallback(
  optionsOrContext?: NotifierOptions | Context
) {
  return function notify(error: NotifiableError) {
    if (typeof optionsOrContext === 'string') {
      return notifyError(error, { context: optionsOrContext });
    }

    return notifyError(error, optionsOrContext);
  };
}

export function notifyError(error: NotifiableError, options?: NotifierOptions) {
  notifyOnSentry(error, options);
}

function notifyOnSentry(error: NotifiableError, options?: NotifierOptions) {
  if (!Sentry.isInitialized) {
    return;
  }

  // We want to keep the original stacktrace, even when we report just plain strings
  const errorObj = buildError(error);

  Sentry.withScope(scope => {
    if (options?.extraSections) {
      Object.entries(options.extraSections).forEach(([key, value]) => {
        scope.setContext(key, value);
      });
    }

    if (options?.context) {
      scope.setTransactionName(options.context);
    }

    if (options?.groupBy) {
      scope.setFingerprint([options.groupBy]);
    }

    if (options?.severity) {
      scope.setLevel(options.severity);
    }

    Sentry.captureException(errorObj);
  });
}

function buildError(error: NotifiableError): Error {
  if (typeof error !== 'string') {
    return error;
  }

  const errorObj = new Error(error);

  if (!errorObj.stack) {
    return errorObj;
  }

  const stack = errorObj.stack.split('\n');

  delete stack[1]; // This function (buildError)
  delete stack[2]; // Reference to the direct caller (reportOnSentry)
  delete stack[3]; // Reference to the first caller of this module (notifyError)

  if (typeof stack[4] === 'string' && stack[4]?.includes('at notify(')) {
    delete stack[4];
  }

  errorObj.stack = stack.flat().join('\n');

  return errorObj;
}

export const ErrorBoundary = Sentry.ErrorBoundary;
