import { Badge } from '@src/ui';
import type { PropsWithChildren } from 'react';

type Props = {
  count?: number;
  color?: 'secondary';
  elementType?: 'icon' | 'text-right';
};

export const CountBadge = (props: PropsWithChildren<Props>) => {
  if (props.count && props.count > 0)
    return (
      <Badge
        elementType={props.elementType}
        color={props.color}
        text={props.count > 99 ? '99' : props.count}
      >
        {props.children}
      </Badge>
    );

  return <>{props.children}</>;
};
