import type { ReactNode } from 'react';

import { MOBILE_MAX_WIDTH } from '@src/constants';

export function NavMain(props: { children?: ReactNode }) {
  const { userAgent } =
    typeof window !== 'undefined' ? navigator : { userAgent: '' };
  const isSafari = userAgent.includes('Safari');

  return (
    <div className="NavMain">
      {props.children}
      <style jsx>{`
        .NavMain {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-shrink: 1;
          max-width: 100%;
        }

        @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
          .NavMain {
            max-height: ${
              isSafari ? '100dvh' : 'inherit'
            }; // https://webkit.org/blog/12445/new-webkit-features-in-safari-15-4/#wkwebview
          }
        }
      `}</style>
    </div>
  );
}
